<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h1>{{ this.uuid ? 'Editar' : 'Agregar' }} cliente</h1>
      </v-col>
    </v-row>

    <PersonForm :person="person" @submit="save" />
  </v-container>
</template>

<script>
// API
import CustomerAPI from '@/api/customer';

// Components
import PersonForm from '@/components/person/Form.vue';

// Utils
import { initialPerson } from '@/utils/person';

export default {
  name: 'CustomerForm',

  props: {
    uuid: {
      type: String,
      default: null,
    },

    role: {
      type: String,
      default: null,
    },
  },

  components: {
    PersonForm,
  },

  data: () => ({
    person: initialPerson(),
  }),

  async created() {
    this.person.role = this.role;
    this.api = new CustomerAPI();

    if (this.uuid) {
      await this.detail();
    }
  },

  computed: {
    breadcrumbs() {
      const items = [
        {
          text: 'Clientes',
          disabled: false,
          exact: true,
          to: { name: 'customer-list' },
        },
      ];

      if (this.uuid) {
        const name = this.person.full_name || '';
        items.push(
          {
            text: name.toUpperCase(),
            disabled: false,
            exact: true,
            to: { name: 'customer-detail', params: { uuid: this.uuid } },
          },
          {
            text: 'Editar',
            disabled: true,
          },
        );
      } else {
        items.push({
          text: 'Agregar',
          disabled: true,
        });
      }

      return items;
    },
  },

  methods: {
    async save() {
      this.$store.commit('showLoader');
      try {
        if (this.uuid) {
          await this.api.update(this.uuid, this.person);
          this.$router.push({ name: 'customer-detail', params: { uuid: this.uuid } });
        } else {
          await this.api.create(this.person);
          this.$router.push({ name: 'customer-list' });
        }

        this.$store.dispatch('notification/notifySuccess');
      } catch (error) {
        if (error.data.email?.pop() === 'Person with this Email already exists.') {
          this.$store.dispatch('notification/notifyError', 'Este email ya está registrado');
        } else {
          this.$store.dispatch('notification/notifyError');
        }
      }
      this.$store.commit('hideLoader');
    },

    async detail() {
      this.$store.commit('showLoader');
      try {
        this.person = await this.api.detail(this.uuid);
        this.person.role = this.person.user.role;
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="sass" scoped>

</style>
